@tailwind base;

:root {
  --color-primary: #00a2e9 !important;
  --color-primary-accent: #008ac6 !important;
}

html {
  line-height: 1.15;
}

@tailwind components;

:not(input) {
  user-select: none;
}

:root {
  --violet-gradient: linear-gradient(
    -180deg,
    rgb(144 89 255 / 80%) 0%,
    rgb(144 89 255 / 40%) 100%
  );
}

a {
  color: inherit;
  text-decoration: none;
}

a:focus {
  outline: 1px dotted grey;
}

body {
  background-image: url('../assets/bg.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
}

.btn {
  @apply bg-primary;
  @apply text-white;
  @apply cursor-pointer;
  @apply py-4;
  @apply px-6;
  @apply font-semibold;
}

.btn:hover {
  @apply bg-primary_accent;
}

.btn:focus {
  @apply bg-primary_accent;
}

.checkbox {
  @apply leading-normal;
  @apply select-none;
}

.checkbox > input[type='checkbox'] {
  @apply absolute;
  @apply opacity-0;
}

.checkbox > label {
  @apply cursor-pointer;
}

.checkbox > label::before {
  /* @apply bg-grey-10; */
  @apply border-default;
  @apply rounded-sm;

  content: '';
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
  float: left;
}

.checkbox > label:hover::before {
  @apply border-primary;
}

.checkbox > input:focus + label::before {
  @apply border-primary;
}

.checkbox > input:checked + label::before {
  @apply bg-primary;
  @apply border-primary;

  background-image: url('../assets/lock.svg');
  background-position: center;
  background-size: 1.25rem;
  background-repeat: no-repeat;
}

.checkbox > input:disabled + label {
  cursor: auto;
}

.checkbox > input:disabled + label::before {
  @apply bg-primary;
  @apply border-primary;

  background-image: url('../assets/lock.svg');
  background-position: center;
  background-size: 1.25rem;
  background-repeat: no-repeat;
  cursor: auto;
}

details {
  overflow: hidden;
}

details > summary::marker {
  display: none;
}

details > summary > svg {
  transition: all 0.25s cubic-bezier(0.07, 0.95, 0, 1);
}

details[open] {
  overflow-y: auto;
}

details[open] > summary > svg {
  transform: rotate(90deg);
}

footer li a:hover {
  text-decoration: underline;
}

.feedback-link {
  background-color: #000;
  background-image: url('../assets/feedback.svg');
  background-position: 0.125rem 0.25rem;
  background-repeat: no-repeat;
  background-size: 1.125rem;
  color: #fff;
  display: block;
  font-size: 0.75rem;
  line-height: 0.75rem;
  padding: 0.375rem 0.375rem 0.375rem 1.25rem;
  text-indent: 0.125rem;
  white-space: nowrap;
}

.link-primary {
  @apply text-primary;
}

.link-primary:hover {
  @apply text-primary_accent;
}

.link-primary:focus {
  @apply text-primary_accent;
}

.main-header img {
  height: 32px;
  width: auto;
}

.main {
  display: flex;
  position: relative;
  max-width: 64rem;
  width: 100%;
}

.main > section {
  @apply bg-white;
}

#password-msg::after {
  content: '\200b';
}

progress {
  @apply bg-grey-30;
  @apply rounded-sm;
  @apply w-full;
  @apply h-1;
}

progress::-webkit-progress-bar {
  @apply bg-grey-30;
  @apply rounded-sm;
  @apply w-full;
  @apply h-1;
}

progress::-webkit-progress-value {
  /* stylelint-disable */
  background-image: -webkit-linear-gradient(
      -45deg,
      transparent 20%,
      rgb(255 255 255 / 40%) 20%,
      rgb(255 255 255 / 40%) 40%,
      transparent 40%,
      transparent 60%,
      rgb(255 255 255 / 40%) 60%,
      rgb(255 255 255 / 40%) 80%,
      transparent 80%
    ),
    -webkit-linear-gradient(left, var(--color-primary), var(--color-primary));
  /* stylelint-enable */
  border-radius: 2px;
  background-size: 21px 20px, 100% 100%, 100% 100%;
}

progress::-moz-progress-bar {
  /* stylelint-disable */
  background-image: -moz-linear-gradient(
      135deg,
      transparent 20%,
      rgb(255 255 255 / 40%) 20%,
      rgb(255 255 255 / 40%) 40%,
      transparent 40%,
      transparent 60%,
      rgb(255 255 255 / 40%) 60%,
      rgb(255 255 255 / 40%) 80%,
      transparent 80%
    ),
    -moz-linear-gradient(left, var(--color-primary), var(--color-primary));
  /* stylelint-enable */
  border-radius: 2px;
  background-size: 21px 20px, 100% 100%, 100% 100%;
  animation: animate-stripes 1s linear infinite;
}

@keyframes animate-stripes {
  100% {
    background-position: -21px 0;
  }
}

select {
  background-image: url('../assets/select-arrow.svg');
  background-position: calc(100% - 0.75rem);
  background-repeat: no-repeat;
}

@screen md {
  .main-header img {
    height: 48px;
    width: auto;
  }

  .main {
    @apply flex-1;
    @apply self-center;
    @apply items-center;
    @apply m-auto;
    @apply py-8;

    max-height: 42rem;
    width: calc(100% - 3rem);
  }
}

@tailwind utilities;

@responsive {
  .shadow-light {
    box-shadow: 0 0 8px 0 rgb(12 12 13 / 10%);
  }

  .shadow-big {
    box-shadow: 0 12px 18px 2px rgb(34 0 51 / 4%),
      0 6px 22px 4px rgb(7 48 114 / 12%), 0 6px 10px -4px rgb(14 13 26 / 12%);
  }
}

@variants focus {
  .outline {
    outline: 1px dotted grey;
  }
}

.word-break-all {
  word-break: break-all;
  line-break: anywhere;
}

.signin {
  backface-visibility: hidden;
  border-radius: 6px;
  transition-property: transform, background-color;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.07, 0.95, 0, 1);
}

.signin:hover,
.signin:focus {
  transform: scale(1.0625);
}

.signin:hover:active {
  transform: scale(0.9375);
}

/* begin signin button color experiment */

.white-primary {
  @apply border-primary;
  @apply border-2;
  @apply text-primary;
}

.white-primary:hover,
.white-primary:focus {
  @apply bg-primary;
  @apply text-white;
}

.primary {
  @apply bg-primary;
  @apply text-white;
}

.white-violet {
  @apply border-violet;
  @apply border-2;
  @apply text-violet;
}

.white-violet:hover,
.white-violet:focus {
  @apply bg-violet;
  @apply text-white;

  background-image: var(--violet-gradient);
}

.violet {
  @apply bg-violet;
  @apply text-white;
}

.violet:hover,
.violet:focus {
  background-image: var(--violet-gradient);
}

/* end signin button color experiment */

:root {
  --white: #fff;
  --black: #000;

  --pot: #161616;
  --abaddon: #221f1f;
  --succulent: #e0dd67;
  --jungle: #516c4e;
  --communist: #ff0000;
  --silver: #eeeeee;
  --jet: #343434;
  --text-dark: #000;

  --primary: #516d4e;
  --text: #fff;

  --color-primary: #516d4e !important;
  --color-primary-accent: #516d4e !important;
}

/* Sodostream grid */

.container {
  display: block;
  margin: 0 auto;
  padding: 0 24px;
}

.container--medium {
  max-width: 1092px;
}

.container--large {
  max-width: 1400px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: 100%;
  gap: 48px;
  flex: 1;

  @media screen and (max-width: 992px) {
      flex-direction: column;
      gap: 24px;
  }
}

.row--md {
  @media screen and (min-width: 576px) {
    flex-direction: row;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
  }
}

.row--sm {
  @media screen and (min-width: 576px) {
    flex-direction: row;
  }

  @media screen and (max-width: 576px) {
    flex-direction: column;
    gap: 24px;
  }
}

.row--g-24 {
    gap: 24px;
}

.row--g-16 {
    gap: 16px;
}

.row--ng {
    gap: 24px;
}

.col {
  display: flex;
  flex-basis: content;
  flex-direction: column;
  flex: 1;
}

.col--o-1 {
  order: 1;
}

.col--o-2 {
  order: 2;
}

.col--bo-1 {
  @media screen and (max-width: 992px) {
    order: 1;
  }
}

.col--bo-2 {
  @media screen and (max-width: 992px) {
    order: 2;
  }
}

.col--bo-md-1 {
  @media screen and (max-width: 768px) {
    order: 1;
  }
}

.col--bo-md-2 {
  @media screen and (max-width: 768px) {
    order: 2;
  }
}

.col--bo-sm-1 {
  @media screen and (max-width: 576px) {
    order: 1;
  }
}

.col--bo-sm-2 {
  @media screen and (max-width: 576px) {
    order: 2;
  }
}

.col--auto {
  flex: unset;
}

.col--grow-2 {
  flex: 2;
}

.col--row {
  flex-direction: row;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.background__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

main > section,
send-modal {
  --tw-bg-opacity: 0.8 !important;
  border-radius: 3px;
}

.btn {
  border-radius: 3px !important;
}

body {
  background-color: var(--pot) !important;
  background-image: unset !important;
}

.main-header {
  background: var(--pot);
  width: 100%;
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.main {
  margin: 0 auto;
}

.main > section {
  background-color: var(--silver);
}

.advertisement {
  margin: 0 auto;
  width: calc(100% - 3rem);
  max-width: 64rem;
  display: flex;
  background-color: var(--silver);
  flex-direction: row;
  gap: 24px;
  border-radius: 3px;
  overflow: hidden;
}

.advertisement__figure {
  padding: 48px;
  flex: 1;
  background: var(--color-primary);
  max-width: 504px;
}

.advertisement__image {
  padding: 0;
  margin: 0;
  object-fit: contain;
  width: 100%;
  object-position: center center;
}

.advertisement__content {
  flex: 1;
  padding: 24px;
}

.advertisement__button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.btn {
  color: var(--color-primary-text) !important;
}

@media screen and (max-width: 767px) {
  .main {
    width: calc(100% - 2rem);
    margin: 0 1rem;
  }

  .advertisement {
    width: calc(100% - 2rem);
    margin: 2rem 1rem;
    flex-direction: column;
    gap: 0;
  }

  .advertisement__figure {
    width: 100%;
    max-width: unset;
  }

  .advertisement__image {
    max-width: 500px;
    margin: 0 auto;
  }
}
